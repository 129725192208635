@import 'variables';

.table-label {
  display: inline-block;
  background-color: $color-gray-6;
  color: $main-blue-color;
  font-size: .85em;
  text-transform: uppercase;
  padding: 5px;
  margin-right: 5px;
  border: 1px solid $color-gray-5;
  border-radius: 5px;
  font-weight: bold;

  &:not(&:last-of-type) {
    margin-bottom: 5px;
  }
}
