.ant-table-placeholder {
  .ant-table-expanded-row-fixed {
    position: inherit !important;
    width: inherit !important;
  }

  td {
    padding: 0 !important;
  }
}
