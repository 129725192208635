@import "variables";

.placeholder {
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  color: #666;

  &__section {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 20px;
    color: $color-gray-2;
  }
}
