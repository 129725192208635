@import "variables";

.webhooks {
  &-header {
    position: relative;

    nz-divider {
      position: relative;
    }

    &__button {
      width: 100%;
      padding: $content-extra-padding 0;
      text-align: right;
    }
  }
}

.webhook {
  &__label {
    text-align: left;
  }

  &__transaction {
    padding: calc(#{$content-extra-padding} / 2) $content-extra-padding;

    &-details {
      .ant-card-body {
        & > ngx-json-viewer {
          & > .ngx-json-viewer {
            max-height: calc(100vh - 610px);
            overflow-y: auto !important;
          }
        }
      }
    }
  }

  &__expand-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__ellipsis {
    position: absolute;
    top: 0;
    right: 40px;
  }

  &__details {
    padding-top: 5px;
    height: 38px;
    overflow: hidden;

    &--expanded {
      height: auto !important;
      overflow: initial !important;
    }

    &-value {
      line-height: 32px;
      padding-left: 10px;

      &.bold {
        font-weight: bold;
      }
    }
  }

  &__header {
    .has-copy-icon {
      position: relative;
      height: 32px;
      line-height: 32px;
      padding-right: 16px + 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        &.copy-link {
          display: inline-block;
          position: absolute;
          right: 16px;
        }
      }
    }

    button {
      padding: 0 !important;
    }
  }

  &-success {
    color: $color-code-emerald;
  }

  &-error {
    color: $color-code-red;
  }
}
