$background-color: #fafbfc;

$header-height: 70px;

$main-font-color: #7c7c7c;

$main-purple-color: #663dbd;
$danger-color: #eb5757;

$main-blue-color: #4880ff;
$border-radius-base: 5px;

$color-gray-1: #333333;
$color-gray-2: #4F4F4F;
$color-gray-3: #828282;
$color-gray-4: #BDBDBD;
$color-gray-5: #E0E0E0;
$color-gray-6: #F2F2F2;
$color-gray-hover-antd: #F5F5F5;

$content-inner-padding: 25px;
$content-extra-padding: 16px;

$primary-navigation-panel-width: 100px;

$content-margin: $content-inner-padding;

$color-code-purple: #BB6BD9;
$color-code-blue: #56CCF2;
$color-code-orange: #F2994A;
$color-code-gray: #828282;
$color-code-black: #333333;
$color-code-red: #EB5757;
$color-code-green: #6FCF97;
$color-code-emerald: #50C878;

$default-box-shadow: 0 0 10px 0 rgba(43, 37, 37, 0.12);

$form-current-version-indicator-color: #4880ff;
$form-draft-version-indicator-color: #F2994A;

$disabled-input-color: #f5f5f5;
