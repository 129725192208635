// Utility classes in the SMACSS style (ish)

.u {
  &-text {
    &--bold {
      font-weight: bold;
    }
    &--bolder {
      font-weight: bolder;
    }
  }
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.no-margin {
  margin: 0 !important;

  &-top {
    margin-top: 0 !important;
  }

  &-right {
    margin-right: 0 !important;
  }

  &-bottom {
    margin-bottom: 0 !important;
  }

  &-left {
    margin-left: 0 !important;
  }
}
