.list-container{
  .node {
    .menu {
      &.ant-dropdown-link {
        padding: 0 10px !important;
      }
    }
  }
}

.ant-dropdown-menu-item {

  .ant-btn {
    width: 100%;
    text-align: left !important;
  }
}
