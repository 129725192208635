/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");
@import "./sass/variables";
@import "./sass/utilities";
@import "./sass/components";
@import "./sass/mixins";
@import "./sass/ng-ant/index";
@import "./sass/placeholder";

html, body {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

body {
  overflow-y: hidden;
}

* {
  font-family: "Open Sans", sans-serif;
  @include customScrollBar;
}

hr {
  background-color: $color-gray-5;
  height: 1px;
  border: none;
}

.ant-drawer {
  backface-visibility: hidden;
}

.ant-drawer-content-wrapper {
  backface-visibility: hidden;
}

.fs-form-field-addon {
  text-align: right;
  line-height: 38.5px;
}

// Overwrite form label style
nz-form-label {
  font-weight: bold;
  text-transform: uppercase;
}

// Overwrite drawer properties
.ant-drawer-body {
  background: $color-gray-6;
}

// Overwrite Left Navigation Menu Item Height (For long menu item names)
.container-left-menu .left-menu-item {
  min-height: 70px;
  height: auto !important;
  white-space: break-spaces !important;
}

.fs-drawer {
  &-form {
    position: relative;
    margin-bottom: 80px;
  }

  &-footer {
    background: white;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 15px;
    text-align: right;

    div {
      display: flex;

      > button {
        display: flex;
        margin-left: 1em;
        align-items: center;
      }
    }
  }
}

.fs-tree-loader {
  padding: 4px;
  overflow: hidden;
}

// NZ Items List
.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}

.ant-cascader-picker-clear {
  display: none !important;
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  display: block !important;
}

.ant-cascader-picker-label {
  padding: 0 25px 0 12px !important;
}

// Public Surveys
.ant-tabs-nav-wrap {
  height: 46px !important;

  .ant-tabs-nav-list {
    position: absolute !important;
    height: 46px !important;
  }
}

.stick-tabs {
  .ant-tabs-nav {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    background: white;
    z-index: 10;

    @media only screen and (max-width: 700px) {
      top: 58px !important;
    }

    .ant-tabs-nav-wrap {
      max-width: 1024px;
      width: 95%;
      margin: 0 auto;

      .ant-tabs-nav-list {
        @media only screen and (max-width: 1080px) {
          padding-left: 16px;
        }
      }
    }
  }
}

// NZ Items List
.page-tabs-wrapper {
  .ant-tabs-nav{
    padding: 0 20px;
  }
}

.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}

// NZ Uploader
.ant-upload-list-item {
  height: auto !important;
}

.ant-upload-list-item-name {
  white-space: normal !important;
  word-break: break-all !important;
}

// Version Tags
.tag {
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;

  &.current-version {
    background-color: $form-current-version-indicator-color;
  }

  &.draft {
    background-color: $form-draft-version-indicator-color;
  }
}

.no-scroll-y {
  overflow-y: hidden !important;
}

.scroll-y {
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
}

.scroll-y-90 {
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.scroll-y-160 {
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.scroll-y-200 {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.scroll-y-220 {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.scroll-y-240 {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.scroll-y-280 {
  height: calc(100vh - 280px);
  overflow-y: auto;
}

.scroll-y-300 {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.scroll-y-320 {
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.scroll-y-340 {
  height: calc(100vh - 340px);
  overflow-y: auto;
}

.content-padding {
  padding: $content-inner-padding $content-inner-padding ($content-inner-padding - 10px) $content-inner-padding;

  &.no-top-relative {
    position: relative;
    top: -$content-inner-padding;
  }

  &.no-top {
    padding-top: 0;
  }

  &.no-bottom {
    padding-bottom: 0;
  }

  &.extra-y, .extra-y {
    padding-left: $content-inner-padding + 16px;
    padding-right: $content-inner-padding + 16px;
  }

  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.inner-content {
  width: calc(100vw - ((15px * 2) + 300px + #{$primary-navigation-panel-width}));
  box-shadow: $default-box-shadow;
  border-radius: 5px;
  background: #fff;

  &.no-side-menu {
    width: calc(100vw - ((#{$content-extra-padding} * 2) + #{$primary-navigation-panel-width})) !important;
  }
}

.ant-table-pagination {
  padding-right: $content-inner-padding;
}

.tree-header-options {
  display: flex;
  flex-direction: row;
  padding: 8px 25px 22px;
}

.list-container {
  margin: 0 25px 25px 25px;
  border-radius: 5px;
  box-shadow: $default-box-shadow;

  .list-header,
  .node {
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;

    .name {
      flex-grow: 1;
    }

    .leaf {
      font-weight: bold;
    }
  }

  .node {
    .menu {
      padding-right: 10px;
    }

    .code {
      width: 475px;
    }
  }

  .list-header {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $color-gray-hover-antd;

    .name {
      padding-left: 15px;
    }

    .code {
      width: 500px;
    }
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
    padding: 4px !important;
  }

  [ng-reflect-is-matched="true"] .node {
    border-bottom: 1px dotted red;
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #ffffff !important;
  }

  .anticon.ant-tree-switcher-icon {
    position: relative;
    top: 3px;
  }
}

.c-pad-a {
  padding: $content-inner-padding;

  &-2 {
    padding: $content-inner-padding * 2;
  }
}

.c-pad-y {
  padding-top: $content-inner-padding;
  padding-bottom: $content-inner-padding;
}

.c-pad-x {
  padding-left: $content-inner-padding;
  padding-right: $content-inner-padding;
}

button.button-lg {
  text-transform: uppercase;
}

.to-uppercase {
  text-transform: uppercase;
}

button.extra {
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.page-list-controls-wrapper {
  .ant-tabs-nav {
    padding: 0 $content-inner-padding;
    transition: none;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    a {
      color: inherit;
    }
  }
}

a.action {
  color: #bdbdbd;
}

.inline-edit {
  .ant-input-wrapper {
    input.ant-input {
      height: 40px;
    }
  }

  .ant-input-group-addon {
    padding: 0;
    border: 0;

    button.ant-btn {
      border-radius: 0 $border-radius-base $border-radius-base 0;
    }
  }
}

.ant-page-header-back {
  margin-top: 2px;
}

.ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.ant-table-wrapper {
  .clickable {
    &.ant-table-row {
      cursor: pointer;
    }
  }

  &.id-list {
    .ant-table-row {
      .id {
        width: 150px;
      }

      .copy {
        visibility: hidden;
        transition: none;
      }

      &:hover {
        .copy {
          visibility: visible;
        }
      }
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}

.ant-table-cell {
  .ant-skeleton-title {
    margin-top: 2px !important;
    margin-bottom: 3px !important;
  }

  .ant-skeleton {
    margin-top: 5.5px !important;
    margin-bottom: 5.5px !important;
  }

  // Fix sticky table context menu options (make it easier to click)
  &.ant-table-cell-fix-right {
    padding: 8px !important;

    .icon-button {
      width: 40px !important;
      height: 40px !important;
      line-height: 40px !important;
      text-align: center;
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}

.ant-table-cell {
  .ant-skeleton-title {
    margin-top: 2px !important;
    margin-bottom: 3px !important;
  }

  .ant-skeleton {
    margin-top: 5.5px !important;
    margin-bottom: 5.5px !important;
  }
}

.skeleton-title {
  width: 300px;
}

.disable-text-selection {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}

.panel-scroller {
  height: 100%;
  overflow: auto;
}

.service-modal {
  .ant-modal-header,
  .ant-modal-footer {
    background-color: $color-gray-6;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.basic-form,
.standard-form,
.creation-form {
  .ant-row {
    margin-bottom: 30px;

    @media only screen and (max-width: 700px) {
      margin-bottom: $content-extra-padding !important;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-top: 10px;
    height: auto;
  }

  .hint {
    color: $color-gray-3;
    font-size: 12px;
    margin-top: 2px;
    padding-right: 40px;
  }

  nz-select {
    width: 100%;
  }

  nz-switch {
    margin-top: 8px;
  }
}

.popup-form {
  .ant-form-item-label {
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    white-space: normal;
    padding-right: 20px;

    & > label {
      display: inline-block;
      height: auto;
      margin-top: 9px;

      .hint {
        font-weight: 400;
        text-transform: none;
        color: $color-gray-3;
        font-size: 12px;
        display: block;
        line-height: 16px;
      }
    }
  }

  .ant-form-item-control.switch {
    margin-top: 4px;
  }
}

.mini-table {
  color: $color-gray-3;

  .ant-row {
    margin-bottom: 0px;
  }

  .header {
    border-bottom: 1px solid $color-gray-5;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 6px;
  }

  .body {
    .hoverable {
      padding: 4px 0 3px 0;
      border-radius: $border-radius-base;

      &:hover {
        background-color: $color-gray-6;

        .on-hover-visibility {
          visibility: visible;
        }
      }
    }

    .drag {
      position: relative;
      top: 1px;
      cursor: move;

      .anticon {
        font-size: 16px;
      }
    }

    .on-hover-visibility {
      visibility: hidden;
      transition: visibility 0s;
    }
  }

  .footer {
    margin-top: 6px;
  }

  .center {
    text-align: center;
  }

  .ant-radio-group.full-width {
    color: $color-gray-3;
    width: 100%;

    label.radio {
      margin: 0;
    }
  }
}

.color-code {
  min-width: 16px;
  min-height: 16px;
  max-height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
  border-radius: 3px;
  margin-right: 2px;
  cursor: pointer;

  &.large {
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
    margin: 0px 2px;
    border-radius: 10px;
    border: 4px solid #ffffff;

    &.selected,
    &:hover {
      border: 4px solid $color-gray-5;
    }
  }

  &.purple {
    background-color: $color-code-purple;
  }

  &.blue {
    background-color: $color-code-blue;
  }

  &.orange {
    background-color: $color-code-orange;
  }

  &.gray {
    background-color: $color-code-gray;
  }

  &.black {
    background-color: $color-code-black;
  }

  &.red {
    background-color: $color-code-red;
  }

  &.green {
    background-color: $color-code-green;
  }
}

.ant-tag.inside-select {
  line-height: 18px;
  font-size: 11px;
  margin-left: 2px;
  position: relative;
  top: -1px;
}

nz-tabset.form-sections {
  .ant-tabs-tab {
    transition: none !important;
    border-color: $color-gray-5 !important;
    background-color: $color-gray-6 !important;
  }

  .ant-tabs-tab-active {
    background-color: inherit !important;
    border-bottom: 1px solid $background-color !important;
  }

  .add-new-section {
    transition: max-width 0.5s;
    max-width: 30px;
    overflow: hidden;

    &:hover {
      max-width: 200px;
    }
  }

  .section-icon {
    margin: 0 0 0 8px;
    position: relative;
    top: -1px;
  }

  .wrap-add-question-between {
    position: relative;
  }

  .add-question-between {
    position: absolute;
    right: 8px;
    margin-top: -12px;
    display: none;

    &:hover,
    &.visible {
      display: block;
    }
  }

  .section-rename-input {
    height: 25px;
  }
}

.full-width {
  width: 100%;
}

.error, .danger-text {
  color: $danger-color;

  &:hover {
    color: $danger-color;
  }

  &.form-control-msg {
    margin-top: 5px;
  }
}

app-tag-input {
  nz-select {
    nz-select-search {
      min-width: 200px !important;
    }
  }
}

.read-only-tag {
  background-color: #fa9851;
  color: #ffffff;
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 5px;
  margin-right: 7px;
  margin-top: 10px;
  display: inline-block;
}

.full-page-spinner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden !important;
}

// ng-zorro-antd update to v.13 fixes
.ant-tabs-tab {
  margin: 0 32px 0 0;
}

.ant-checkbox-inner {
  display: inline-block !important;
}

.ant-checkbox-checked:after {
  height: calc(100% - 4px) !important;
}

.button-right-margin {
  margin-right: $content-extra-padding;
}

.button-left-margin {
  margin-left: $content-extra-padding;
}
