@import 'variables';

@mixin base-field {
    display: flex;
    height: 21px;
    line-height: 18px;
    padding: 3px 13px;
    border-radius: 49px;
    background-color: #f0f3f8;
}

@mixin button-outline {
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px $main-blue-color;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $main-blue-color;
    background-color: transparent;

    &:hover {
        color: #ffffff;
        background-color: $main-blue-color;
    }
}

@mixin customScrollBar($size: 6px) {
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: #aaa;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #aaa;
    }
    ::-webkit-scrollbar-thumb:active {
        background: #aaa;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
        background: transparent;
    }
    ::-webkit-scrollbar-track:active {
        background: transparent;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}
