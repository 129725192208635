@import "components/index";

.c {
  &-table {
    &--full {
      width: 100%;

      thead,
      tbody {
        width: 100%;
        display: inline-table;
      }

      // For 3 table cells, for more or less, change the number. Can be a mixin

      td:first-child:nth-last-child(3),
      td:first-child:nth-last-child(3) ~ td,
      th:first-child:nth-last-child(3),
      th:first-child:nth-last-child(3) ~ th {
        width: 33.3333%;
      }
    }
  }
}
