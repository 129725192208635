@import "variables";

.header-tabs {
  .ant-tabs-tab-active {
    color: $main-font-color !important;
  }

  .ant-tabs-tab {
    font-size: 14px !important;
  }

  .ant-menu-item {
    border-width: 4px;
    height: 70px;

    &-selected,
    &:hover {
      border-width: 4px;
    }
  }
}
